<!--  -->
<template>
    <div class='com-game-list-bcg'>
        <div class="list-bcg-item" v-for="(v, i) in bcgListCul" :key="i" :class="v.class">
            <slot :name="v.slot" v-if="v.slot"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            gameType: [String, Number]
        },
        data() {
            return {
                bcgList: [
                    {class: 'g1'},
                    {class: 'g2'},
                    {class: 'g8-shadow', gameType: 1},
                    {class: 'g9-shadow', gameType: 1},
                    {class: 'g10-shadow', gameType: 1},
                    {class: 'g11-shadow', gameType: 1},
                    {class: 'g12-shadow', gameType: 1},
                    {class: 'g3'},
                ]
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            bcgListCul(){
                let {bcgList: list, gameType: value} = this
                let rd = []
                for(let i in list){
                    let {gameType} = list[i]
                    if(gameType && value != gameType) continue
                    rd.push(list[i])
                }
                return rd
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' src="./summary.less">

</style>