<!--  -->
<template>
    <div class='page-game-list design-size-ele'>
        <component-bcg url="game/bcg.png"></component-bcg>
        <!-- v-show="pageIsInit" -->
        <div class="game-list-body">
            <game-list-bcg :gameType.sync="gameType"></game-list-bcg>
            <div class="list-body-title">
                <!-- <span class="text-large-a bold">{{pageText['list title']}}</span> -->
                <span class="text-large-a bold">{{TEXT['game-list-ai']['list title']}}</span>
            </div>
            <div class="game-type-box">
                <div class="type-item cursor-pointer" :class="[{'scale-button': v.state}, v.class]" v-for="(v, i) in gameTypeList" :key="i" @click="changeGameType(v)">
                    <div class="select-icon" v-if="gameTypeSelected.value == v.value"></div>
                    <div class="text-box">
                        <span class="text-normal-b bold">{{v.title}}</span>
                    </div>
                    <div class="state-icon flex-center" v-if="v.disabled">
                        <span class="text-normal-aa text bold">待解锁</span>
                    </div>
                </div>
            </div>
            <div class="game-list-content">
                <!-- <div class="content-capture">
                    <div class="capture-item" :class="[`${v.rule}-${v.boardSize}`]" v-for="(v, i) in captureGameList" :key="i"
                    @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                    @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                    @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                    @click="gotoAction(v, 'capture')"
                    >
                        <div class="item-text">
                            <div class="main-title flex-center nowrap">
                                <span class="text-large-d bold">{{v.label}}</span>
                            </div>
                            <div class="sub-title flex-center nowrap bold">
                                <span class="text-normal-aa">{{v.intro}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-play">
                    <div class="play-item" :class="[`${v.type}-${v.boardSize}`]" v-for="(v, i) in playGameList" :key="i"
                    @mousedown="$event.currentTarget.classList.add('button-scale-down')"
                    @touchstart="$event.currentTarget.classList.add('button-scale-down')"
                    @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
                    @touchend="$event.currentTarget.classList.remove('button-scale-down')"
                    @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
                    @click="gotoAction(v)"
                    >
                        <div class="item-text">
                            <div class="main-title flex-center nowrap">
                                <span class="text-large-a bold">{{v.label}}</span>
                            </div>
                            <div class="sub-title flex-center nowrap bold">
                                <span class="text-normal">{{v.intro}}</span>
                            </div>
                        </div>
                    </div>
                </div> -->
                <router-view />
            </div>
        </div>
        <!-- <confirm-pro ref="confirm" @close="AILevelClosed">
            <div class="ai-level-select-box">
                <div class="level-sel-title text-center">
                    <span class="text-normal-aa bold">{{pageText['t21']}}</span>
                </div>
                <div class="level-sel-content">
                    <div class="con-slider-box">
                        <div class="slider-bcg vertical-align-center-position" :style="gressBcgStyle"></div>
                        <van-slider v-model="AILevelIndex"
                        :min="0" :max="selecteList.length - 1"
                        inactive-color="#EBEFF7"
                        active-color="#FE5D53"
                        bar-height="8px"
                        >
                            <template #button>
                                <div class="ai-sel-button">
                                </div>
                            </template>
                        </van-slider>
                        <div class="slider-step-box">
                            <div class="slider-step-item" v-for="v in selecteList" :key="v.id"></div>
                        </div>
                    </div>
                    <div class="ai-sel-title-box">
                        <div class="sel-title-item cursor-pointer flex-center"  
                        @click="AILevelSelectSlider({index: i})"
                        v-for="(v, i) in selecteList" :key="v.id" :class="{'active': v.id == AILevelVal.id}">
                            <span class="text-mini-bb no-selected bold" :class="{'bold': v.id == AILevelVal.id}">{{v.title}}</span>
                            <div class="sel-tit-icon align-center-position"></div>
                        </div>
                    </div>
                </div>
                <div class="level-sel-foot flex-center">
                    <sl-button @click="AILevelSelect(AILevelVal)" type="confirm">
                        <span class="text-normal">{{pageText.t22}}</span>
                    </sl-button>
                </div>
            </div>
        </confirm-pro>
        <confirm-pro ref="confirm_19" @close="AILevelClosed">
            <div class="ai-level-select-more">
                <div class="level-sel-title text-center">
                    <span class="text-normal-ba bold">{{pageText['t21']}}</span>
                </div>
                <div class="level-sel-more flex-center">
                    <div class="content-body flex-center">
                        <div class="level-control-btn scale-button left" @click="levelMove(-1)"></div>
                        <div class="level-control-content mini-scrollbar-x" ref="levelScrollBox" 
                        @mousewheel="levelWheel"
                        >
                            <div class="con-content-body no-selected" :style="{width: `${selecteList.length * 20}%`, ...levelStyle, ...levelTemStyle}">
                                <div class="level-data-item cursor-pointer" v-for="(v, i) in selecteList" :key="v.level"
                                @click="setLevel(i)"
                                :style="{width: `${(20 / (selecteList.length * 20)) * 100}%`}"
                                >
                                    <div class="item content flex-center">
                                        <span class="text-normal-aa bold">{{v.title}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="level-select-area">
                            <div class="area-bcg item"></div>
                            <div class="area-flag item"></div>
                            <div class="area-bcg-white item"></div>
                        </div>
                        <div class="level-control-btn scale-button right" @click="levelMove(1)"></div>
                    </div>
                </div>
                <div class="level-sel-foot flex-center">
                    <sl-button @click="AILevelSelect(AILevelVal)" type="confirm">
                        <span class="text-normal">{{pageText.t22}}</span>
                    </sl-button>
                </div>
            </div>
        </confirm-pro> -->
    </div>
</template>

<script>
    // import apiClass from '@/API/page/game'
    import gameListBcg from './background/list.vue'
    // import BScroll from '@better-scroll/core'

    export default {
        name: '',
        props: {},
        data() {
            return {
                // appData: new this.appDataClass({key: 'game-action'}),
                // appDataList: new this.appDataClass({key: 'game-list'}),
                // api: new apiClass(),
                gameTypeList: [
                    {title: '挑战AI', value: 1, class: 'AI', pathName: 'game-list-ai', default: true},
                    {title: '人人对弈', value: 2, class: 'peer', pathName:'game-list-player'},
                ],
                gameType: this.$route.query.gameType,
                // ruleText: [
                //     this.pageText['t16'],
                //     this.pageText['t17'],
                //     '',
                //     this.pageText['t18'],
                //     this.pageText['t19'],
                //     this.pageText['t20'],
                // ],
                // gameList: [],
                // aiList: [],
                // selected: {},
                // AILevelResponse: null,
                // AILevelIndex: null,
                // pageIsInit: false,
                // levelTouchState: false,
                // levelItemSize: 0,
                // touchData: {},
                // levelScroll: null,
                // localData: {}
            };
        },
        methods: {
            // gotoCapture(val){
            //     val = val || {}
            //     let {boardSize, captureNum, moveNum} = val
            //     let gameType = 'capture'
            //     let routeData = this._common.encodeData({boardSize, captureNum, moveNum, gameType})
            //     let opt = {
            //         name: 'game-action',
            //         params: {
            //             routeData,
            //         }
            //     }
            //     this._routeHand.goto(opt)
            // },
            // gotoPlay(val){
            //     val = val || {}
            //     let {boardSize, disabled, komi} = val
            //     if(disabled) return false
            //     let gameType = 'play'
            //     let routeData = this._common.encodeData({boardSize, komi, gameType})
            //     let opt = {
            //         name: 'game-action',
            //         params: {
            //             routeData,
            //         }
            //     }
            //     this._routeHand.goto(opt)
            // },
            // async gotoAction(val, type){
            //     val = val || {}
            //     let levelData = await this.getAILevel(val)
            //     let {id, AILevel, gameType, boardSize} = levelData
            //     let data = {id}
            //     // let {AILevel, style, api, id} = levelData
            //     // let {boardSize, captureNum, moveNum, gameType, komi, rule = 'chinese', judgeMove, judgeSplit} = val
            //     // let data = {boardSize, captureNum, moveNum, gameType, komi, rule, AILevel, style, api, id}
            //     // if(judgeMove) data.judgeMove = judgeMove
            //     // if(judgeSplit) data.judgeSplit = judgeSplit
            //     for(let i in data){
            //         if(data[i] === undefined) delete data[i]
            //     }
            //     this.appData.removeAll()
            //     let routeData = this._common.encodeData(data)
            //     let opt = {
            //         name: 'game-action',
            //         params: {
            //             routeData,
            //         }
            //     }
            //     let level = {}
            //     level[`${gameType}_${boardSize}_level`] = AILevel
            //     this.localDataSave(level)
            //     this._routeHand.goto(opt)
            // },
            // async getAILevel(val){
            //     val = val || {}
            //     let  {AILevel} = val
            //     this.selected = val
            //     await this._common.nextTick.call(this)
            //     let list = this.selecteList
            //     if(!list.length) return {AILevel}
            //     if(list.length == 1){
            //         let data = list[0]
            //         AILevel = data.level
            //         return {AILevel}
            //     }
            //     return new Promise(res => {
            //         this.AILevelResponse = res
            //         this.ALLevelIndexInit()
            //         let confirmType = this.isMoreStyle ? 'c11' : 'c4'
            //         let {boardSize} = this
            //         let ele = this.$refs.confirm
            //         if(this.$refs[`confirm_${boardSize}`]) ele = this.$refs[`confirm_${boardSize}`]
            //         ele.open({
            //             closeOnClickOverlay: true,
            //             confirmType,
            //             showClose: true
            //         })
            //         this.itemSizeSet()
            //         this.appendSliderStep()
            //         this.levelScrollSet()
            //     })

                
            // },
            // ALLevelIndexInit(){
            //     if(this.AILevelIndex !== null) return
            //     this.AILevelIndex = Number(this.AILevelIndexDefault)
            // },
            async pageInit(){
                if(this.gameTypeSelected.value) return
                let {defaultGameType: data} = this
                let {pathName, value} =  data
                if(!pathName) return
                this._routeHand.goto({name: pathName, type: 'replace', query: {gameType: value}})
            },
            // async patternListInit(){
            //     let res = await this.api.configLoad('game')
            //     if(res) this.gameList = res
            // },
            // async levelListInit(){
            //     let res = await this.api.getLevelList()
            //     if(!res) return
            //     this.aiList = res
            // },
            // AILevelSelect(val){
            //     let {eloScore: AILevel, style, api, id} = val || {}
            //     let reData = {AILevel, style, api, id}
            //     if(this.AILevelResponse) this.AILevelResponse(reData)
            //     this.$refs['confirm'].close()
            //     return reData
            // },
            // AILevelClosed(){
            //     this.AILevelResponse = null
            // },
            // AILevelSelectSlider(val){
            //     let {index} = val || {}
            //     this.AILevelIndex = Number(index)
            // },
            // async appendSliderStep(){
            //     await this._common.nextTick.call(this)
            //     let ele = document.querySelector('.page-game-list .ai-level-select-box .level-sel-content .con-slider-box .slider-step-box')
            //     let eleBox = document.querySelector('.page-game-list .ai-level-select-box .level-sel-content .con-slider-box .van-slider')
            //     if(!ele || !eleBox){
            //         return
            //     }
            //     eleBox.appendChild(ele)
            // },
            changeGameType(val){
                let {value, disabled, pathName} = val || {}
                if(!value || disabled || !pathName) return
                this._routeHand.goto({name: pathName, type: 'replace', query: {gameType: value}})
            },
            // levelTouchdown(e){
            //     this.levelTouchState = true
            //     let {clientX: x, clientY: y} = e
            //     this.touchData = {x, y}
            // },
            // levelTouchup(){
            //     this.AILevelIndex = this.indexTem
            //     this.levelTouchState = false
            //     this.touchData = {}
            // },
            // levelTouchmove(e){
            //     if(!this.levelTouchState) return
            //     let {clientX: x} = e
            //     let delta = this.touchData.x - x
            //     // this.touchData.delta = delta
            //     this.$set(this.touchData, 'delta', delta)
            // },
            // levelMove(num){
            //     let {AILevelIndex: index, selecteList: list} = this
            //     index = Number(index) + num
            //     if(index < 0) index = 0
            //     if(index >= list.length) index = list.length - 1
            //     this.AILevelIndex = index
            // },
            // async itemSizeSet(){
            //     await this._common.nextTick.call(this)
            //     let ele = document.querySelector('.page-game-list .ai-level-select-more .level-sel-more .content-body .level-control-content .level-data-item')
            //     if(!ele) return
            //     let width = ele.offsetWidth
            //     this.levelItemSize = width
            // },
            // setLevel(index){
            //     this.AILevelIndex = index
            // },
            // levelWheel(e){
            //     let {deltaY} = e
            //     let num = deltaY > 0 ? 1 : -1
            //     this.levelMove(num)
            // },
            // levelScrollSet(){
            //     if(!this.isMoreStyle) return
            //     let ele = this.$refs.levelScrollBox
            //     if(!ele) return
            //     this.levelScroll = new BScroll('.page-game-list .ai-level-select-more .level-sel-more .content-body .level-control-content', {
            //     // ...... 详见配置项
            //     scrollX: true
            //     })

            // },
            // eventSet(){
            //     this.eventOff()
            //     this._common.eventSet('onresize', e => {
            //         this.itemSizeSet()
            //     })
            // },
            // eventOff(){
            //     this._common.eventRemove('onresize')
            // },
            // localDataSave(items){
            //     this.appDataList.setItem(items)
            //     this.localDataSet()
            // },
            // localDataSet(){
            //     let data = this.appDataList.getAll()
            //     this.localData = data || {}
            // },
        },
        created() {
            this.pageInit()
            
        },
        components: {
            gameListBcg
        },
        computed: {
            defaultGameType(){
                let {gameTypeList: list} = this
                let rd = {}
                for(let i in list){
                    if(list[i].default){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            // indexTem(){
            //     let {index, touchData: {delta}, levelItemSize: size, selecteList: list} = this
            //     delta = delta || 0
            //     let num = parseInt(delta / size)
            //     let d = delta % size
            //     if(Math.abs(d) >= (size / 2)){
            //         num = delta > 0 ? num++ : num--
            //     }
            //     index += num
            //     if(index >= list.length) index = list.length - 1
            //     if(index < 0) index = 0
            //     return index
            // },
            // indexPos(){
            //     let {AILevelIndex: index, levelItemSize: size} = this
            //     let pos = index * -size + size * 2
            //     return pos
            // },
            // levelTemStyle(){
            //     let {touchData: {delta}} = this
            //     if(!delta || isNaN(Number(delta))) return {}
            //     return {
            //         transform: `translateX(${-delta}px)`
            //     }
            // },
            // levelStyle(){
            //     let {indexPos: pos} = this
            //     return {
            //         // transform: `translateX(${pos}px)`
            //         left: `${pos}px`
            //     }
            // },
            // isNormalStyle(){
            //     return this.boardSize != 19
            // },
            // isMoreStyle(){
            //     return this.boardSize == 19
            // },
            // boardSize(){
            //     let {boardSize} = this.selected || {}
            //     return boardSize
            // },
            // gressBcgStyle(){
            //     let {AILevelIndex: index, selecteList: list} = this
            //     let data = index / (list.length - 1)
            //     return {
            //         width: `${data * 100}%`
            //     }
            // },
            gameTypeSelected(){
                let {gameTypeList: list, gameType: val} = this
                let rd = {}
                for(let i in list){
                    if(list[i].value == val){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            // AILevelVal(){
            //     let {selecteList: list, AILevelIndex: index} = this
            //     let data = list[index] || {}
            //     return data
            // },
            // AILevelIndexDefault(){
            //     let {type, boardSize} = this.selected
            //     let key = `${type}_${boardSize}_level`
            //     let localLevel = this.localData[key]
            //     if(localLevel === undefined || isNaN(Number(localLevel))) localLevel = undefined
            //     let {selecteList: list} = this
            //     let index = null, defaultIndex = null
            //     for(let i in list){
            //         if(localLevel !== undefined && localLevel == list[i].level) index = i
            //         if(list[i].default) defaultIndex = i
            //     }
            //     if(index !== null) return index
            //     if(defaultIndex !== null) return defaultIndex
            //     return 0
            // },
            // selecteList(){
            //     let {type, boardSize, styleType} = this.selected || {}
            //     if(!type || !boardSize) return []
            //     let {levelSelectList: list} = this
            //     let data = []
            //     for(let i in list){
            //         let {type: seType, boardSize: size} = list[i]
            //         if((seType != type && seType != styleType) || size != boardSize) continue
            //         data.push(list[i])
            //     }
            //     data = this._common.sortData(data, (v1, v2) => {
            //         let {level: level1} = v1
            //         let {level: level2} = v2
            //         return level1 < level2
            //     })
            //     return data
            // },
            // levelSelectList(){
            //     let types = this.PARAMS.game_level_keys
            //     let {aiList: list} = this
            //     let data = []
            //     for(let i in list){
            //         let {description} = list[i]
            //         if(!this._common.isObject(description)) continue
            //         let {type} = description
            //         if(!types[type]) continue
            //         let info = list[i]
            //         let level = info.level || info.eloLevel || info.eloScore || 0
            //         data.push({...list[i], ...description, level})
            //     }
            //     return data
            // },
            // captureGameList(){
            //     let list = this.gameList
            //     let rd = []
            //     for(let i in list){
            //         let tem = this._common.deepCopy(list[i])
            //         let detail = this._common.deepCopy(tem.resourceDetail)
            //         let {type} = detail
            //         if(type != 'capture') continue
                    
            //         delete tem['resourceDetail']
            //         tem.gameType = type
            //         rd.push({...detail, ...tem})
            //     }
            //     return rd
            // },
            // playGameList(){
            //     let list = this.gameList
            //     let rd = []
            //     for(let i in list){
            //         let tem = this._common.deepCopy(list[i])
            //         let detail = this._common.deepCopy(tem.resourceDetail)
            //         let {type} = detail
            //         if(type != 'play') continue
                    
            //         delete tem['resourceDetail']
            //         tem.gameType = type
            //         rd.push({...detail, ...tem})
            //     }
            //     return rd
            // },
        },
        watch: {
            $route: {
                handler(){
                    this.gameType = this.$route.query.gameType
                },
                deep: true,
                immediate: true
            }
            // selecteList: {
            //     handler(val, oldVal){
            //         // if(this._common.isSameObject(val, oldVal)) return
            //         // if(!val.length) return
            //         // this.appendSliderStep()
            //     },
            //     deep: true,
            //     immediate: true
            // },
            // selected: {
            //     handler(val, oldVal){
            //         if(this._common.isSameObject(val, oldVal)) return
            //         this.AILevelIndex = null
            //     },
            //     deep: true,
            //     immediate: true
            // },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-game-list{
        overflow: hidden;
        .list-item{
            float: left;
            .l-text-i{
                display: flex;
            }
        }
        .list-item.block{
            margin-right: 12px;
            .l-i-item{
                width: 312px;
                height: 128px;
                padding: 0px 28px;
                display: flex;
                align-items: center;
                .i-item-body{
                    .l-text-i+ .l-text-i{
                        margin-top: 8px;
                    }
                }
            }
            .l-i-item+ .l-i-item{
                margin-top: 12px;
            }
        }
        .list-item.line{
            .l-i-item{
                width: 243px;
                height: 82px;
                padding: 0px 24px;
                display: flex;
                align-items: center;
                .i-item-body{
                    .l-text-i+ .l-text-i{
                        margin-top: 4px;
                    }
                }
            }
            .l-i-item+ .l-i-item{
                margin-top: 11px;
            }
        }
        .AI-select{
            height: 192px;
            width: 303px;
            padding: 20px 0px;
            .color-item{
                width: 156px;
                height: 40px;
                border: 1px solid #D2D2D2;
                border-radius: 8px;
                margin: 0px auto;
                display: flex;
                justify-content: center;
                align-items: center;
                .color-icon{
                    width: 24px;
                    height: auto;
                    margin-right: 7px;
                }
            }
            .color-item+ .color-item{
                margin-top: 16px;
            }
        }
        .ai-level-select-box{
            // inactive-color="#EBEFF7"
            // active-color="#FE5D53"
            @slider-button-size: 18px;
            @slider-bar-height: 9px;
            @slider-bar-active-color: #FE5D53;
            @slider-bar-inactive-color: #EBEFF7;
            @slider-button-d: 6px;
            @slider-button-half: calc(@slider-button-size / 2 - @slider-button-d);
            width: 231px;
            height: 206px;
            padding: 21px 0px 0px;
            .level-sel-title{
                color: #656060;
            }
            .level-sel-content{
                margin-top: 30px;
                padding: 0px 18px;
                .ai-sel-title-box{
                    margin-top: 18px;
                    // width: calc(100% + @slider-button-half * 2 - 4px);
                    // margin-left: calc(-@slider-button-size / 2 + @slider-button-d + 2px);
                    display: flex;
                    padding: 0px 2px;
                    justify-content: space-between;
                    .sel-title-item{
                        color: #9D9A9A;
                        position: relative;
                        .sel-tit-icon{
                            position: absolute;
                            top: -10px;
                            width: 6px;
                            height: 5px;
                            .background-img-max(url('@{assetsUrlV2_0}/game/g16.png'));
                        }
                        
                    }
                    .sel-title-item.active{
                        color: #FC5375;
                        .sel-tit-icon{
                            .background-img-max(url('@{assetsUrlV2_0}/game/g16-select.png'));
                        }
                    }
                }
                .con-slider-box{
                    position: relative;
                    height: @slider-bar-height;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    // width: calc(100% - 9px);
                    .background-img-max(url('@{assetsUrlV2_0}/game/g14.png'));
                    .slider-bcg{
                        position: absolute;
                        width: calc(100% - 2px);
                        left: 1.1px;
                        height: 6px;
                        border-radius: 6px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g15.png'));
                        transition: all .2s;
                        min-width: 12px;
                        max-width: calc(100% - 2px);
                    }
                    .van-slider{
                        border-radius: 0px;
                        height: 6px !important;
                        // width: 170px;
                        width: calc(100% - 18px);
                        margin-left: 9px;
                        z-index: 2;
                        background: none !important;
                    }
                    .van-slider__bar{
                        background: none !important;
                    }
                    .slider-line{
                        position: absolute;
                        z-index: 1;
                        top: 0px;
                        width: @slider-button-half;
                        height: 100%;
                    }
                    .slider-line.left{
                        left: calc(-@slider-button-size / 2 + @slider-button-d + 2px);
                        border-radius: @slider-bar-height 0px 0px @slider-bar-height;
                        background: @slider-bar-active-color;
                    }
                    .slider-line.right{
                        right: calc(-@slider-button-size / 2 + @slider-button-d + 2px);
                        border-radius: 0px @slider-bar-height @slider-bar-height 0px;
                        background: @slider-bar-inactive-color;
                    }
                    .van-slider__button-wrapper, .van-slider__button-wrapper-right{
                        z-index: 2;
                    }
                    .slider-step-box{
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .slider-step-item{
                            width: 4px;
                            height: 4px;
                            background: #fff;
                            border-radius: 100%;
                        }
                    }
                    .slider-step-item{
                        width: 4px;
                        height: 4px;
                        background: #fff;
                        border-radius: 100%;
                    }
                }
                
            }
            .level-sel-foot{
                margin-top: 37px;
                .sel-foot-btn{
                }
                .com-global-sl-button{
                    width: 105px;
                    height: 31px;
                }
            }
            .ai-sel-button{
                // img{
                //     width: @slider-button-size;
                //     height: @slider-button-size;
                // }
                // `${PARAMS.assetsUrl}/img/game/g_22.png`
                // background: url('@{assetsUrl}/img/game/g_22.png') no-repeat;
                // background-size: 100% 100%;
                .background-img-max(url('@{assetsUrlV2_0}/game/g13.png'));
                position: relative;
                width: @slider-button-size;
                height: @slider-button-size;
            }
            .ai-sel-button::before{
                content: '';
                position: absolute;
                top: -5px;
                left: -5px;
                width: 28px;
                height: 28px;
                z-index: -1;
                .background-img-max(url('@{assetsUrlV2_0}/game/g13-shadow.png'));
            }
        }


        .game-list-body{
            position: relative;
            width: 100%;
            height: 100%;
            .game-type-box{
                position: absolute;
                left: 0px;
                top: 69px;
                z-index: 3;
                .type-item{
                    width: 81px;
                    height: 118px;
                    position: relative;
                    .text-box{
                        padding-left: 25px;
                        width: 18px;
                    }
                    .state-icon{
                        position: absolute;
                        width: 24px;
                        height: 53px;
                        top: 53px;
                        left: 3px;
                        color: #FC4444;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g7.png'));
                        .text{
                            width: 12px;
                            height: 42px;
                        }
                    }
                    .select-icon{
                        position: absolute;
                        top: -2px;
                        left: 0px;
                        height: 128px;
                    }
                }
                .type-item.AI{
                    padding-top: 32px;
                    color: #2E3F73;
                    .background-img-max(url('@{assetsUrlV2_0}/game/g5.png'));
                    .select-icon{
                        width: 80px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g5-select.png'));
                    }
                }
                .type-item.peer{
                    padding-top: 20px;
                    color: #734325;
                    .background-img-max(url('@{assetsUrlV2_0}/game/g6.png'));
                    .select-icon{
                        width: 55px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g6-select.png'));
                    }
                }
            }
            .game-type-item{
                height: 100%;
                padding-left: 25px;
                width: 18px;
                position: relative;
            }
            .game-type-item.a{
                padding-top: 32px;
                color: #2E3F73;
            }
            .game-type-item.b{
                padding-top: 20px;
                color: #734325;
                
            }
            // .body-content{
            //     position: absolute;
            //     top: 57px;
            //     left: 67px;
            //     display: flex;
                .main-title{
                    color: #68587E;
                }
                .sub-title{
                    color: #8F798E;
                }
                .content-capture{
                    position: absolute;
                    top: 57px;
                    left: 67px;
                    .capture-item{
                        position: relative;
                        .item-text{
                            position: absolute;
                        }
                        .main-title{
                            height: 26px;
                            margin-bottom: 9px;
                        }
                    }
                    .capture-item+ .capture-item{
                        margin-top: 11px;
                    }
                    .capture-item.capture-9{
                        width: 294px;
                        height: 130px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g8.png'));
                        .item-text{
                            top: 40px;
                            left: 29px;
                            width: 121px;
                            text-align: center;
                        }
                    }
                    .capture-item.capture-13{
                        width: 309px;
                        height: 129px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g9.png'));
                        .item-text{
                            position: absolute;
                            top: 40px;
                            left: 25px;
                            width: 129px;
                            text-align: center;
                        }
                    }
                }
                .content-play{
                    position: absolute;
                    top: 52px;
                    left: 363px;
                    .play-item{
                        position: relative;
                        .item-text{
                            top: 26px;
                            position: absolute;
                        }
                        .main-title{
                            height: 20px;
                            margin-bottom: 8px;
                            text-align: center;
                        }
                    }
                    .play-item.play-9{
                        width: 259px;
                        height: 93px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g10.png'));
                        .item-text{
                            left: 22px;
                            width: 76px;
                        }
                    }
                    .play-item.play-13{
                        margin-left: 12px;
                        margin-top: 1px;
                        width: 263px;
                        height: 93px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g11.png'));
                        .item-text{
                            top: 24px;
                            left: 21px;
                            width: 84px;
                        }
                    }
                    .play-item.play-19{
                        margin-left: 24px;
                        margin-top: -4px;
                        width: 270px;
                        height: 96px;
                        .background-img-max(url('@{assetsUrlV2_0}/game/g12.png'));
                        .item-text{
                            top: 28px;
                            left: 21px;
                            width: 84px;
                        }
                    }
                }
            // }
            .list-body-title{
                width: 100%;
                padding-top: 4px;
                height: 20px;
                color: #514284;
                text-align: center;
            }
        }

        .ai-level-select-more{
            .level-sel-title{
                color: #656060;
            }
            .level-sel-more{
                margin: 27px auto 53px;
                width: 228px;
                height: 43px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g40.png'));
                .content-body{
                    width: 224px;
                    height: 39px;
                    margin: auto;
                    position: relative;
                    .background-img-max(url('@{assetsUrlV2_0}/game/g41.png'));
                    .level-control-btn{
                        width: 26px;
                        height: 100%;
                    }
                    .level-control-content{
                        width: calc(100% - 26px * 2);
                        height: 100%;
                        position: relative;
                        overflow: hidden;
                        z-index: 2;
                        .con-content-body{
                            height: 100%;
                            transition: all .3s;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            display: inline-block;
                            .van-tabs__nav--line.van-tabs__nav--complete{
                                padding: 0px;
                            }
                            .van-tab{
                                line-height: normal;
                                padding: 0px;
                                width: 34px;
                            }
                            .van-tabs__nav{
                                background: none;
                            }
                            .van-tabs--line .van-tabs__wrap{
                                height: 100%;
                            }
                            .van-tabs{
                                height: 100%;
                            }
                            .van-tabs__line{
                                width: 37px;
                                height: 61px;
                                top: 0px;
                                bottom: 0px;
                                margin: auto;
                            }
                        }
                        
                        .level-data-item{
                            float: left;
                            width: 34.4px;
                            height: 100%;
                            position: relative;
                            .item{
                                position: absolute;
                            }
                            .item.content{
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                z-index: 2;
                                width: 10px;
                                display: flex;
                                justify-content: center;
                                height: 100%;
                                color: #8D8585;
                                span[class^='text']{
                                    display: flex;
                                    flex-wrap: wrap;
                                    height: 100%;
                                    align-items: center;
                                    line-height: 15px;
                                }
                            }
                            .item.bcg{
                                z-index: 1;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 37px;
                                height: 46px;
                                .background-img-max(url('@{assetsUrlV2_0}/game/g43.png'));
                            }
                        }
                    }
                    .level-control-btn.left{
                        .background-img-max(url('@{assetsUrlV2_0}/game/g42_left.png'));
                    }
                    .level-control-btn.right{
                        .background-img-max(url('@{assetsUrlV2_0}/game/g42_right.png'));
                    }
                    .level-select-area{
                        position: absolute;
                        width: 37px;
                        height: 61px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        .item{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        .area-bcg{
                            width: 37px;
                            height: 46px;
                            .background-img-max(url('@{assetsUrlV2_0}/game/g43.png'));
                        }
                        .area-flag{
                            width: 12px;
                            height: 61px;
                            .background-img-max(url('@{assetsUrlV2_0}/game/g44.png'));
                        }
                        .area-bcg-white{
                            width: 26px;
                            height: 34px;
                            .background-img-max(url('@{assetsUrlV2_0}/game/g45.png'));
                        }
                    }
                }
            }
            .level-sel-foot{
                .com-global-sl-button{
                    width: 121px;
                    height: 36px;
                    .button-shadow{
                        width: 129px;
                        height: 45px;
                        margin-left: -4px;
                        margin-top: -4px;
                    }
                }
            }
        }
    }
</style>